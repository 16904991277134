import React, { Suspense, useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "./routes/routes";
import Router from "./components/Router/Router";
import { LocalizationProvider } from "./locale/localizationContext";
import { ThemeProvider } from "@mui/material";
import { v4 } from "uuid";
import { getFromLocalStorage, setOnLocalStorage } from "./helpers/localstorage";
import { get, has } from "lodash";
import theme from "./mui/theme";
import Loading from "./components/Loading/Loading";
import GeneralError from "./helpers/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import { getCookie, setCookie } from "./helpers/cookie";
import { RoleEnum } from "models/role.types";
import Config from "Config";
import { AgencyType } from "models/product.types";
import { logoutApi, myAgency } from "api/auth.api";
import ErrorAPI from "helpers/api";

type AppProps = {};

export const App: React.FC<AppProps> = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const token = getCookie("token");
  const role = getCookie("role");
  const { agencyName } = (getFromLocalStorage() as any) || {};

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!has(getFromLocalStorage(), "app-run-id")) {
      const uuid = v4();
      setOnLocalStorage({ "app-run-id": uuid });
    }
    setOnLocalStorage({
      "app-launch-count": get(getFromLocalStorage(), "app-launch-count", 0) + 1,
    });
  }, []);

  useEffect(() => {
    const validateRole = async () => {
      if (!!token) {
        setIsAppInitialized(false);
        try {
          const { payload: agencyPayload, body: agencyBody } = await myAgency();
          if (agencyPayload.ok) {
            if (agencyBody) {
              const arrayC = agencyBody.agencies;
              const smartCom = (arrayC || []).find(
                (a: AgencyType) =>
                  a?.agency.name === "SmartCom" &&
                  (a.role === RoleEnum.SUPERADMINISTRATOR ||
                    a.role === RoleEnum.ADMINISTRATOR),
              );
              if (!!smartCom) {
                if (role !== smartCom.role) {
                  setCookie("role", smartCom.role);
                }
              } else {
                if (role !== arrayC[0].role) {
                  setCookie("role", arrayC[0].role);
                }
              }

              const currentPage = location.pathname;
              if (currentPage !== "/choose-company" && !agencyName) {
                history.push({
                  pathname: "/choose-company",
                  state: {
                    agencies: arrayC,
                  },
                });
              }
            }
          }
        } catch (e) {
          if (e instanceof ErrorAPI && e.status === 401) {
            logoutApi();
          } else {
            throw e;
          }
        }
      }

      setIsAppInitialized(true);
    };

    validateRole();
  }, [role, token, location, history, agencyName]);

  const fallback = useCallback(() => <GeneralError />, []);

  const computePermissions = useCallback(() => {
    const { roleScopes } = Config;
    let p = {};
    switch (role || "") {
      case RoleEnum.SUPERADMINISTRATOR:
        p = roleScopes.SUPERADMINISTRATOR;
        break;
      case RoleEnum.ADMINISTRATOR:
        p = roleScopes.ADMINISTRATOR;
        break;
      case RoleEnum.AGENCY_ADMIN:
        p = roleScopes.AGENCY_ADMIN;
        break;
      case RoleEnum.AGENCY:
        p = roleScopes.AGENCY;
        break;
      case RoleEnum.AGENT:
        p = roleScopes.AGENT;
        break;
      case RoleEnum.CAPO_AREA:
        p = roleScopes.CAPO_AREA;
        break;
      case RoleEnum.GROUP_MANAGER:
        p = roleScopes.GROUP_MANAGER;
        break;
      case RoleEnum.CUSTOMER:
        p = roleScopes.CUSTOMER;
        break;
      default:
        break;
    }

    return p;
  }, [role]);

  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider loader={<Loading />}>
          {!isAppInitialized ? (
            <Loading />
          ) : (
            <ErrorBoundary fallbackRender={fallback}>
              <Router
                authorized={!!token && !!role}
                routes={routes}
                permissions={computePermissions()}
              />
            </ErrorBoundary>
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
